import React, { Component } from 'react'

class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textInput: '',
      editMode: false,
      editId: '',
    }
  }

  componentDidUpdate() {
    const { editing, clearEdit } = this.props
    const { textInput, editMode } = this.state
    if (editing.item && (editing.item !== textInput || !editMode)) {
      this.setState({
        textInput: editing.item,
        editMode: true,
        editId: editing.id,
      })
      clearEdit()
    }
  }

  handleChange({ target }) {
    this.setState({ textInput: target.value })
  }

  handleSubmit(e) {
    e.preventDefault()
    const { editMode, textInput, editId } = this.state
    const { addItem, editItem } = this.props
    editMode ? editItem(editId, textInput) : addItem(this.state.textInput)
    this.setState({ textInput: '', editMode: false, editId: '' })
  }

  render() {
    const { editMode, textInput } = this.state
    return (
      <form className="newItemForm" onSubmit={(e) => this.handleSubmit(e)}>
        <input
          type="text"
          className="itemDescription"
          placeholder="Put your shit."
          onChange={(e) => this.handleChange(e)}
          value={textInput}
        />
        <input
          type="submit"
          className="addButton"
          value={editMode ? 'Edit' : 'Pow'}
        />
      </form>
    )
  }
}

export default Form
