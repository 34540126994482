import React, { Component } from 'react'
import arrayMove from 'array-move'

import Form from './Form'
import List from './List'
import Loading from './Loading'

import firebase from '../firebase'
const database = firebase.database()

import '../css/index.css'

class Shopping extends Component {
  constructor() {
    super()
    this.state = {
      allItems: [],
      editing: {},
      datastore: {},
      isLoading: true,
    }
  }

  componentDidMount() {
    this.getItems()
  }

  getItems = () => {
    // pull down db data and add to state array based on orderIndex
    database.ref('list').on('value', (snapshot) => {
      // Add snapshot items to `items` array in original order
      let orderedItems = null
      if (snapshot.val()) {
        let items = []

        snapshot.forEach((item) => {
          if (item) items.push(item.val())
        })

        // create new array in order
        const compare = (firstEl, secondEl) => {
          return firstEl.orderIndex - secondEl.orderIndex
        }
        orderedItems = [...items].sort(compare)

        orderedItems.forEach((item, i) => {
          if (item) item.orderIndex = i
        })
      }

      this.setState({
        allItems: orderedItems || [],
        datastore: snapshot.val(),
        isLoading: false,
      })
    })
  }

  addItem = (item) => {
    // Get a key for a new Post.
    const newPostKey = database.ref().child('list').push().key

    const singleItem = {
      item,
      id: newPostKey,
      orderIndex: this.state.allItems.length + 1,
    }

    database.ref('list/' + newPostKey).set(singleItem)
  }

  // Gets new orderIndex for db data from this.state.allItems
  findNewIndex = (id) => {
    const { allItems } = this.state

    if (allItems.length > 0) {
      const itemToCheck = this.state.allItems.find((item) => item.id === id)
      if (itemToCheck) return itemToCheck.orderIndex
    }
    return 0
  }

  // Takes data object from either db or this.state.datastore
  // Iterates through items and updates the orderIndex with findNewIndex
  allocateOrderIndex(data) {
    if (!data) return

    let newDataStore = {}

    Object.entries(data).forEach(([key, value]) => {
      newDataStore[key] = {
        id: value.id,
        item: value.item,
        orderIndex: this.findNewIndex(value.id),
      }
    })

    database
      .ref('list/')
      .set(newDataStore)
      .then(() => {
        this.getItems
      })
      .catch((err) => {
        console.log('There was an error updating database', err)
      })
  }

  // Fetches the data from db, and sends to allocateOrderIndex
  updateOrderIndex = () => {
    database.ref('list').on('value', (snapshot) => {
      this.allocateOrderIndex(snapshot.val())
    })
  }

  // Removes item from db, then fires a fresh db download
  // so we can update orderIndex on the items. Probably a more eloquent solution here.
  removeItem = (id) => {
    database.ref(`list/${id}`).remove(() => this.updateOrderIndex())
  }

  // For onClick in List component. Sends editing item to Shopping state
  // to be accessible to Form component
  startEdit = (item) => {
    this.setState({ editing: item })
  }

  clearEdit = () => {
    this.setState({ editing: {} })
  }

  editItem = (id, updatedItem) => {
    database.ref(`list/${id}`).update({ item: updatedItem, id })
    this.setState({ editing: {} })
  }

  // react-sortable-hoc
  onSortEnd = ({ oldIndex, newIndex }) => {
    const newArray = arrayMove(this.state.allItems, oldIndex, newIndex)
    newArray.forEach((item, i) => (item.orderIndex = i))

    this.setState(
      () => ({
        allItems: newArray,
      }),
      this.allocateOrderIndex(this.state.datastore)
    )
  }

  render() {
    const { isLoading, allItems, editing } = this.state

    if (!isLoading) {
      return (
        <div className="app">
          <h1 className="header">Shopping.</h1>
          <Form
            addItem={this.addItem}
            editing={editing}
            clearEdit={this.clearEdit}
            editItem={this.editItem}
          />
          <List
            items={allItems}
            removeItem={this.removeItem}
            startEdit={this.startEdit}
            onSortEnd={this.onSortEnd}
            useDragHandle
          />
        </div>
      )
    } else {
      return (
        <div className="app">
          <h1 className="header">Shopping.</h1>
          <Loading />
        </div>
      )
    }
  }
}

export default Shopping
