import React from 'react'
import { sortableHandle } from 'react-sortable-hoc'

const DragHandle = sortableHandle(() => (
  <span className="drag-handle">
    {' '}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="27"
      viewBox="0 0 24 24"
    >
      <path fill="#003333" d="M20 9H4v2h16V9zM4 15h16v-2H4v2z" />
    </svg>{' '}
  </span>
))

export default DragHandle
