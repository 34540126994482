import React from 'react'

import * as names from '../insults'

const getInsult = () => {
  let insult
  const { insultStart, insultEnd, insults } = names
  const randNum = Math.random()
  if (randNum <= 0.4) {
    const firstNum = Math.floor(Math.random() * names.insultStart.length)
    const secondNum = Math.floor(Math.random() * names.insultEnd.length)
    insult = `a ${insultStart[firstNum]} ${insultEnd[secondNum]}`
  } else if (randNum > 0.4 && randNum <= 0.9) {
    const num = Math.floor(Math.random() * insults.length)
    insult = insults[num]
  } else {
    insult = 'a GIBBITYFLIRB!!!!!!!!!! 🚀🚀🚀🚀🚀'
  }

  return insult
}

const Loading = () => {
  return (
    <div className="loading">
      <svg
        width="40px"
        height="40px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className="lds-rolling"
        style={{ background: 'none' }}
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          ng-attr-stroke="{{config.color}}"
          ng-attr-stroke-width="{{config.width}}"
          ng-attr-r="{{config.radius}}"
          ng-attr-stroke-dasharray="{{config.dasharray}}"
          stroke="#bcd8c1"
          strokeWidth="14"
          r="35"
          strokeDasharray="164.93361431346415 56.97787143782138"
          transform="rotate(30.395 50 50)"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="0.6s"
            begin="0s"
            repeatCount="indefinite"
          ></animateTransform>
        </circle>
      </svg>
      <p>{`Maria is ${getInsult()}.`}</p>
    </div>
  )
}

export default Loading
