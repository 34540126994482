import React from 'react'
import { sortableElement } from 'react-sortable-hoc'

import DragHandle from './DragHandle'

const ListItem = sortableElement(
  ({ item, removeItem, startEdit, sortableIndex }) => {
    return (
      <li key={item.id}>
        <a onClick={() => removeItem(item.id)}>x</a>
        <span onClick={() => startEdit(item)}>{item.item}</span>
        <DragHandle />
      </li>
    )
  }
)

export default ListItem
