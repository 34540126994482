import React from 'react'

import Shopping from './Shopping'
import ErrorBoundary from './ErrorBoundary'

const App = () => {
  return (
    <ErrorBoundary>
      <Shopping />
    </ErrorBoundary>
  )
}

export default App
