import React from 'react'
import { sortableContainer } from 'react-sortable-hoc'

import ListItem from './ListItem'

const List = sortableContainer(({ items, removeItem, startEdit }) => {
  if (!items || items.length < 1) {
    return <p>Nothing here.</p>
  } else {
    return (
      <ul>
        {items.map((item, index) => {
          return (
            <ListItem
              key={item.id}
              item={item}
              removeItem={removeItem}
              startEdit={startEdit}
              index={index}
              sortableIndex={index}
            />
          )
        })}
      </ul>
    )
  }
})

export default List
